import { Opt } from '../styles/Form';
import GiftCard from '../assets/imgs/GiftCardShop.jpg';
import frame from '../assets/imgs/Frame.jpg';
import Countries from "../assets/data/countries.json"

import cathedral from '../assets/imgs/products/paintings/Cathedral.jpg'
import Hsabio from '../assets/imgs/products/paintings/fullPainting_heart_sabio.jpg'
import Hstranger from '../assets/imgs/products/paintings/fullPainting_heart_Stranger.jpg'
import Hleaves from '../assets/imgs/products/paintings/Hearts_and_leaves_1_whole.jpg'
import santaMaria from '../assets/imgs/products/paintings/Santa_Maria_Del_Mar.jpg'

import bluedoor from '../assets/imgs/products/cuadritos/bluedoor.jpg'
import frida1 from '../assets/imgs/products/cuadritos/frida1.jpg'
import mh from '../assets/imgs/products/cuadritos/modernisthouse.jpg'
import moksha from '../assets/imgs/products/cuadritos/moksha.jpg'
import sagrada from '../assets/imgs/products/cuadritos/sagrada.jpg'
import sagrada2 from '../assets/imgs/products/cuadritos/sagrada2.jpg'
import santfelip from '../assets/imgs/products/cuadritos/santfelip.jpg'

import GetCookie from '../hooks/GetCookie';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import SetCookie from '../hooks/SetCookie';

import Maxo from '../assets/imgs/maxo.png';
import Shiva from '../assets/imgs/shiva.jpg';


export const getCart = (context) => {
    const cart = []
    // console.log("Context :");
    // console.log(context);
    context.forEach(element => {
      if(element !== undefined){
        // console.log(element.id)
        useParams()
        let article = getArticle("", element.id)
        article.Quantity = element.quantity
        cart.push(article)
      }
    });
    // console.log(cart)
  return cart
} 

export const getUser = () => {

  const user = JSON.parse(GetCookie('userdata'));

  return user;
}

export const getPrice = ({dataset}, shipping) => {

  // console.log(typeof(dataset[0].Price))

  let nbPainting = countType("paintings", {dataset});
  let totalPrice = 0;
  let p=0;

  dataset.forEach((item)=> {
    if(item.Art === "paintings"){
        p +=1
        if(p === 2 && nbPainting === 2 && GetCookie("offer25")){
          totalPrice += item.Price * 0.75;
        }else{
          totalPrice += item.Price;
        }
    }else{
      totalPrice += item.Price*item.Quantity;
    }
  });

  totalPrice = parseFloat(totalPrice) + parseFloat(shipping);

  // console.log(totalPrice)
  return(
    [totalPrice, totalPrice]
  )

  // let total = 0;

  // dataset.map(
  //   (data) => total += data.Price*data.Quantity
  // )

  // if(nbPainting===2){
  //   let nb = total;

  //   return (
  //     [total+shipping, <span>
  //       {/* <span style={{textDecoration:"line-through", color:"#c54def"}}>
  //         {total}€
  //       </span>
  //       &nbsp; */}
  //       {total*0.875+shipping}
  //     </span>]
  //   )
  // } else {
  //   return [total + shipping, total+shipping];
  // }
}

export const countType = (type, {dataset}) => {
  let count = 0;
  // console.log("Count Type")
  // console.log({dataset})
  dataset.forEach(element => {
    // console.log(element.Art + " : " + type)
    // console.log(element)
    if(element.Art === type){
      count += element.Quantity;
    }
  });
  return count;
}

export const getSentence = (nbPainting) => {
  if(nbPainting===1) {
    return <span onClick={() => SetCookie("offer25", true, 1)}>Add one more painting for 25% off the second, <Link className="yellow" to="/shop/paintings">continue shopping</Link></span>
  }else if(nbPainting===2){
    return <span onClick={() => SetCookie("offerShip", true, 1)}>Add one more painting for free shipping, <Link className="yellow" to="/shop/paintings">continue shopping</Link></span>;
  }else if(nbPainting >= 3 && GetCookie("offerShip")){
    return "Free Shipping";
  }
}

export const getShippingOpt = ({dataset}) => {
  // Spain
  // Eu
  // International

  // console.log(dataset);

  let Opt = {
    "1-2 paintings / spain": 20,
    "Collect in the gallery": 0
  }

  let paint = countType("paintings", {dataset});
  let cuadritos = countType("3dphotos", {dataset});

  // console.log("Paint : " + paint)
  // console.log("3D : " + cuadritos)

  if(paint <= 2 && paint !== 0){
    Opt = {
      "Upto 2 Paintings/ Upto 3 3D-Photos ": 40,
      "Collect in the gallery": 0
    }
  }

  if(cuadritos <= 3 && cuadritos !== 0){
    Opt = {
      "Upto 2 Paintings/ Upto 3 3D-Photos ": 40,
      "Collect in the gallery": 0
    }
  }

  if(paint >= 3 && paint <= 4){
    Opt = {
      "+3 paintings": 60,
      "Collect in the gallery": 0
    }
  }

  if(paint >= 5){
    Opt = {
      "+3 paintings": 85,
      "Collect in the gallery": 0
    }
  }

  if(cuadritos >= 4){
    Opt = {
      "+4 cuadritos": 60,
      "Collect in the gallery": 0
    }
  }

  if(GetCookie("offerShip") && paint >= 3){
    Opt = {
      "3+ paintings": 0
    }
  }

  return Opt
}

export const getListCountriesDropDown = () => {
  return Countries.map((country) => <Opt value={country.country}>{country.country}</Opt>)
}

export const getArtists = () => {

  
  const artists = 
  [
    ["Maxó", Maxo, "/shop/artist/6228d7ae-fce2-409c-b73d-96c1a324bd9f", "16.5px", "right:20vw"], 
    ["Shiva", Shiva, "/shop/artist/e639b66f-2402-4b9d-b738-31221596959d", "17.5px", "left:20vw"], 

    // Old database
    // ["Maxó", Maxo, "/shop/artist/c43d4cd8-81c9-4609-8f87-b564e5edafb6", "16.5px", "right:20vw"], 
    // ["Shiva", Shiva, "/shop/artist/adb03703-233e-4c31-8408-0a31260edb4b", "17.5px", "left:20vw"], 
  ];
  return artists;
}

export const getArticle = (type, id) => {

  const q = {
    type: type
  }

  let products = getPanelData(q);
  // console.log(products);

  let dataset = products.find(products => products.Id = id);

  // console.log(dataset);

  return dataset;
}

export const getPanelData = (q) => {
    // exec query
    let id="gc";
    let result = [
      {
        "Id": (id+"1"),
        "Picture": GiftCard,
        "Title": "1 Painting",
        "SubTitle": "",
        "Size": "",
        "Type": "Free Shipping",
        "Art" : "giftcards",
        "Price": 95,
        "Quantity" : 1000,
        "Option": ""
      },
      {
        "Id": (id+"2"),
        "Picture": GiftCard,
        "Title": "2 Painting",
        "SubTitle": "",
        "Size": "",
        "Type": "Free Shipping",
        "Art" : "giftcards",
        "Price": 175,
        "Quantity" : 1000,
        "Option": ""
      },
      {
        "Id": (id+"3"),
        "Picture": GiftCard,
        "Title": "3 Painting",
        "SubTitle": "",
        "Size": "",
        "Type": "Free Shipping",
        "Art" : "giftcards",
        "Price": 240,
        "Quantity" : 1000,
        "Option": ""
      },
      {
        "Id": (id+"4"),
        "Picture": GiftCard,
        "Title": "1 Painting (Piel de Luna)",
        "SubTitle": "",
        "Size": "",
        "Type": "Free Shipping",
        "Art" : "giftcards",
        "Price": 123,
        "Quantity" : 1000,
        "Option": ""
      },
      {
        "Id": (id+"5"),
        "Picture": GiftCard,
        "Title": "3 Cuadritos",
        "SubTitle": "",
        "Size": "",
        "Type": "Free Shipping",
        "Art" : "giftcards",
        "Price": 75,
        "Quantity" : 1000,
        "Option": ""
      },
      {
        "Id": (id+"6"),
        "Picture": GiftCard,
        "Title": "5 Cuadritos",
        "SubTitle": "",
        "Size": "",
        "Type": "Free Shipping",
        "Art" : "giftcards",
        "Price": 110,
        "Quantity" : 1000,
        "Option": ""
      },
      {
        "Id": (id+"7"),
        "Picture": GiftCard,
        "Title": "10 Cuadritos",
        "SubTitle": "",
        "Size": "",
        "Type": "Free Shipping",
        "Art" : "giftcards",
        "Price": 200,
        "Quantity" : 1000,
        "Option": ""
      },
      {
        "Id": 0,
        "Picture": frame,
        "Title": "Frame",
        "SubTitle": "",
        "Size": "",
        "Type": "",
        "Art" : "frame",
        "Price": 195,
        "Collection": "",
        "Artist" : "",
        "Option": "",
        "Quantity" : 1000,
      },
      {
        "Id": 1,
        "Picture": cathedral,
        "Title": "Cathedral of Barcelona (I)",
        "SubTitle": "",
        "Size": "56cm x 77cm",
        "Type": "Acrylic on Paper",
        "Art" : "paintings",
        "Price": 143,
        "Collection": "The New Yorker",
        "Artist" : "",
        "Option": "Frame",
        "Quantity" : 1,
      },
      {
        "Id": 2,
        "Picture": santaMaria,
        "Title": "Santa Maria del Mar (I)",
        "SubTitle": "",
        "Size": "56cm x 77cm",
        "Type": "Acrylic on Paper",
        "Art" : "paintings",
        "Price": 143,
        "Collection": "The New Yorker",
        "Artist" : "",
        "Option": "Frame",
        "Quantity" : 1,
      },
      {
        "Id": 3,
        "Picture": Hsabio,
        "Title": "Sabio y Enamorado?",
        "SubTitle": "",
        "Size": "56cm x 77cm",
        "Type": "Acrylic on Paper",
        "Art" : "paintings",
        "Price": 143,
        "Collection": "Hearts",
        "Artist" : "",
        "Option": "Frame",
        "Quantity" : 1,
      },
      {
        "Id": 4,
        "Picture": Hstranger,
        "Title": "Stranger Things Heart",
        "SubTitle": "(I)",
        "Size": "56cm x 77cm",
        "Type": "Acrylic on Paper",
        "Art" : "paintings",
        "Price": 143,
        "Collection": "Hearts",
        "Artist" : "",
        "Option": "Frame",
        "Quantity" : 1,
      },
      {
        "Id": 5,
        "Picture": Hleaves,
        "Title": "Love for Vegetables",
        "SubTitle": "Red",
        "Size": "56cm x 77cm",
        "Type": "Acrylic on Paper",
        "Art" : "paintings",
        "Price": 143,
        "Collection": "Hearts",
        "Artist" : "",
        "Option": "Frame",
        "Quantity" : 1,
      },
      {
        "Id": 6,
        "Picture": moksha,
        "Title": "Moksha Stall",
        "SubTitle": "",
        "Size": "IDK",
        "Type": "3D Photo",
        "Art" : "3dphotos",
        "Price": 35,
        "Collection": "Barcelona Places",
        "Artist" : "",
        "Option": "",
        "Quantity" : 1,
      },
      {
        "Id": 7,
        "Picture": sagrada,
        "Title": "Sagrada Familia (I)",
        "SubTitle": "",
        "Size": "IDK",
        "Type": "3D Photo",
        "Art" : "3dphotos",
        "Price": 35,
        "Collection": "Barcelona Places",
        "Artist" : "",
        "Option": "",
        "Quantity" : 1,
      },
      {
        "Id": 8,
        "Picture": sagrada2,
        "Title": "Sagrada Familia (II)",
        "SubTitle": "",
        "Size": "IDK",
        "Type": "3D Photo",
        "Art" : "3dphotos",
        "Price": 35,
        "Collection": "Barcelona Places",
        "Artist" : "",
        "Option": "",
        "Quantity" : 1,
      },
      {
        "Id": 9,
        "Picture": santfelip,
        "Title": "Placa de Sant Felip Neri",
        "SubTitle": "",
        "Size": "IDK",
        "Type": "Acrylic on Paper",
        "Art" : "3dphotos",
        "Price": 35,
        "Collection": "Barcelona Places",
        "Artist" : "",
        "Option": "",
        "Quantity" : 1,
      },
      {
        "Id": 10,
        "Picture": mh,
        "Title": "Modernist House",
        "SubTitle": "",
        "Size": "IDK",
        "Type": "Acrylic on Paper",
        "Art" : "3dphotos",
        "Price": 35,
        "Collection": "Barcelona Places",
        "Artist" : "",
        "Option": "",
        "Quantity" : 1,
      },
      {
        "Id": 11,
        "Picture": frida1,
        "Title": "Frida Graffiti",
        "SubTitle": "(I)",
        "Size": "IDK",
        "Type": "Acrylic on Paper",
        "Art" : "3dphotos",
        "Price": 35,
        "Collection": "Graffiti",
        "Artist" : "",
        "Option": "",
        "Quantity" : 1,
      },
      {
        "Id": 12,
        "Picture": bluedoor,
        "Title": "Blue Door and Flowers",
        "SubTitle": "",
        "Size": "IDK",
        "Type": "Acrylic on Paper",
        "Art" : "3dphotos",
        "Price": 35,
        "Collection": "Barcelona Places",
        "Artist" : "",
        "Option": "",
        "Quantity" : 1,
      },
    ];

    
    let filtered = result;

    if(q.type !== ""){
      filtered = result
      .filter(result => result.Art === q.type)
      .filter(result => result.Quantity >= 1)
    }

    return filtered;

}

export const getPanelDataAsync = async (q) => {
  // exec query
  try{

    // TODO: BOOKMARK: products with true removed.
    const response = await axios.get(`products/products`)
    
    let result = []
    // console.log(response.data)

    response.data.forEach((element) => {
      // console.log(element)
      let img = (element.artwork.images[0] !== undefined) ? window.location.origin + "/static/media/projects/galeria-maxo/" + element.artwork.id + "/" + element.artwork.images[0].fileName : ""
      // let img = ""
    result.push({
      "Id": element.id,
      "Picture": img,
      "Title": element.artwork.title,
      "SubTitle": element.artwork.description,
      "Size": element.artwork.size,
      "Type": "",
      "Art" : "paintings",
      "Price": element.price,
      "Quantity" : element.inventory,
      "Option": ""
    })})

    let filtered = result;

    // if(q.type !== ""){
    //   filtered = result
    //   .filter(result => result.Art === q.type)
    //   .filter(result => result.Quantity >= 1)
    // }
    
    return filtered
  }
  catch(err){
      console.log(err)
  }

}


export const GetArtworkBySerie = async (id) => {
  try {
      
    // TODO: BOOKMARK: products with true removed.
      const response = await axios.get(`products/series/${id}`)

      let result = []

     // let art = ""

    // if(id === "210aa8d8-c887-4624-8ed7-a8fd5d9f1270"){
    //   art = "special-payments"
    // }
    
    response.data.forEach((element) => 

    result.push({
      "Id": element.id,
      "Picture": (element.artwork.images[0] !== undefined) ? window.location.origin + "/static/media/projects/galeria-maxo/" + element.artwork.id + "/" + element.artwork.images[0].fileName : "",
      "Title": element.artwork.title,
      "SubTitle": element.artwork.description,
      "Size": element.artwork.size,
      "Type": "",
      "Art" : "art",
      "Price": element.price,
      "Quantity" : element.inventory,
      "Option": ""
    }))
      
      return result

  } catch (error) {
      console.log(error)
  }
}

export const getArticlesByCat = async (type) => {
  let articles = []
  const cuadritos = [
    "115c25ea-faf9-4b74-abf9-b3db8f6c0d0e", 
    "8ea99420-860b-41d2-aa46-8f993518a8e6", 
    "f330b19f-7727-43a1-9ad5-99f9ce877915"
  ]
  const paintings = [
    "fb612412-eff5-406c-8e45-603c9e02c60f",
    "17cd1622-f6c4-4d43-89c5-d71a64ef0abb",
    "f421c651-be68-4c85-9595-8754fa288b86",
    "a993ecac-8edb-423f-8605-71b49e077cd6",
    "f9624123-a76f-40c8-9e15-59fd66a4bb70",
    "4a8184df-9bad-450d-9dcf-4327bb37d64c",
    "d37092ad-a4b4-4631-85a3-7095e3a3fe19",
    "9ed84736-7b82-459e-8507-a4c5ef7c1527",
  ]

  try {
    if(type === "3dphotos"){
      for(let i = 0; i < cuadritos.length; i++){
        const response = await GetArtworkBySerie(cuadritos[i])
        // console.log(response)
        articles = ([...articles, ...response])
      }
    }else if(type === "paintings"){
      for(let i = 0; i < paintings.length; i++){
        const response = await GetArtworkBySerie(paintings[i])
        articles = ([...articles, ...response])
      }
    }

    // console.log(articles)

    return articles;
  } catch (error) {
    console.log(error)
  }
}

export const getArticleAsync = async (id) => {

try{
  const url = "products/" + id + "/"
  const resp = await axios.get(url)
  const element = resp.data
  let artType = "paintings";

  if(element.artwork.seriesId === "fb612412-eff5-406c-8e45-603c9e02c60f" || element.artwork.series === "17cd1622-f6c4-4d43-89c5-d71a64ef0abb" || element.artwork.series === "f421c651-be68-4c85-9595-8754fa288b86" || element.artwork.series === "a993ecac-8edb-423f-8605-71b49e077cd6" || element.artwork.series === "f9624123-a76f-40c8-9e15-59fd66a4bb70" || element.artwork.series === "4a8184df-9bad-450d-9dcf-4327bb37d64c" || element.artwork.series === "d37092ad-a4b4-4631-85a3-7095e3a3fe19" || element.artwork.series === "9ed84736-7b82-459e-8507-a4c5ef7c1527"){
    artType = "paintings"
  }else if(element.artwork.seriesId === "46b80d45-ffc5-494d-b50d-0e28e5256c62"){
    artType = "collection"
  }else if(element.artwork.seriesId === "210aa8d8-c887-4624-8ed7-a8fd5d9f1270"){
    artType = "special"
  }else if(element.artwork.seriesId === "a30d75f2-82aa-4cbb-87fc-25b02fc75e48"){
    artType = "giftcards"
  }else{
    artType = "3dphotos"
  }

  // console.log(element)

  const artwork = {
    "Id": element.id,
    "Picture": (element.artwork.images[0] !== undefined) ? window.location.origin + "/static/media/projects/galeria-maxo/" + element.artwork.id + "/" + element.artwork.images[0].fileName : "",
    "Title": element.artwork.title,
    "SubTitle": element.artwork.description,
    "Size": element.artwork.size,
    "Type": element.artwork.medium,
    "Art" : artType,
    "Price": element.price,
    "Quantity" : element.inventory,
    "Option": ""
  }

  // console.log(element)

  return artwork;

  }catch(e){

    console.log(e);

  }

}

export const getCartAsync = async (context) => {
  const cart = []
  // console.log("I am inside the cart")
  // console.log({context})
  
  for (let index = 0; index < context.length; index++) {
    let article = await getArticleAsync(context[index].id)
    // console.log(article)
    article.Quantity = context[index].quantity
    cart.push(article)
  }

  // console.log({cart})
  // console.log("Cart is above")

return cart
} 
