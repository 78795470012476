import { Box } from 'grid-styled'
import React from 'react'
import { ContactTitle, ContactTxtSm, ContainerTxt, FlexShipping } from '../../styles/Contact'

const TxtShipping = () => {
  return (
    <>
          <ContainerTxt>
            <ContactTitle>Shipping</ContactTitle>
            <ContactTxtSm>Usually we need 2-3 weeks after receiving the request to ship the paintings. Once shipped, the average time for the shippings to arrive are:
              <FlexShipping>
                <Box width={1/3}>
                Spain<br/>4-5 days
                </Box>
                <Box width={1/3}>
                Europe<br/>7-10 days
                </Box>
                <Box width={1/3}>
                Global<br/>12-15 days
                </Box>
              </FlexShipping>

            </ContactTxtSm>
          </ContainerTxt>
          <ContainerTxt>
            <ContactTitle>Shipping cost for 3D-Photos</ContactTitle>
            <ContactTxtSm>
              <FlexShipping>
                <Box width={1/4}>
                  &nbsp;
                  <br/>
                  Upto 3:
                  <br/>
                  4 or more:
                </Box>
                <Box width={1/4}>
                  Spain
                  <br/>
                  € 20
                  <br/>
                  € 30
                </Box>
                <Box width={1/4}>
                  Europe
                  <br/>
                  € 35
                  <br/>
                  € 50
                </Box>
                <Box width={1/4}>
                  Global
                  <br/>
                  € 40
                  <br/>
                  € 60
                </Box>
              </FlexShipping>


Note: For bulk orders, the price of shipping would change.
            </ContactTxtSm>
          </ContainerTxt>

          <ContainerTxt>
            <ContactTitle>Shipping cost for Paintings</ContactTitle>
            <ContactTxtSm>
              <FlexShipping>
                <Box width={1/4}>
                  &nbsp;
                  <br/>
                  Upto 2:
                  <br/>
                  Upto 4:
                </Box>
                <Box width={1/4}>
                  Spain
                  <br/>
                  € 20
                  <br/>
                  € 30
                </Box>
                <Box width={1/4}>
                  Europe
                  <br/>
                  € 35
                  <br/>
                  € 50
                </Box>
                <Box width={1/4}>
                  Global
                  <br/>
                  € 40
                  <br/>
                  € 60
                </Box>
              </FlexShipping>

            </ContactTxtSm>
          </ContainerTxt>
        </>
  )
}

export default TxtShipping